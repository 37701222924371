import { render, staticRenderFns } from "./zhengcepp.vue?vue&type=template&id=99a05bb4&scoped=true&"
import script from "./zhengcepp.vue?vue&type=script&lang=js&"
export * from "./zhengcepp.vue?vue&type=script&lang=js&"
import style0 from "./zhengcepp.vue?vue&type=style&index=0&id=99a05bb4&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99a05bb4",
  null
  
)

export default component.exports