<template>
	<div class="container">
		<div class="one_box">
			<div class="one_box_nei">
				<div class="left">
					<div class="num_box">
						{{ num1 }}
						<span>次</span>
					</div>
					<div class="content">累积智能匹配</div>
				</div>
				<div class="line"></div>
				<div class="right">
					<div class="num_box">
						{{ num2 }}
						<span>条</span>
					</div>
					<div class="content">本月更新政策</div>
				</div>
			</div>
			<div>
				<div class="search_big_box">
					<div class="search_box">
						<div>
							<el-input placeholder="请输入内容" v-model="input4"><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
						</div>
						<div class="search"><span @click="getList">立即获取</span></div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="two_box">
      <div class="box_nei">某某某有限责任公司匹配报告</div>
      <div class="time_box">2021-03-31 14:53</div>
    </div> -->
		<div class="three_box">
			<!-- <div class="three_box_one">某某某有限责任公司</div>
      <div class="three_box_two">高新技术企业</div>
      <div class="three_box_three">
        <div>法人代表：</div>
        <div>地址：</div>
        <div></div>
      </div>
      <div class="three_box_four">
        <div class="three_box_four_one">
          <div class="num_box"><span>188</span>项</div>
          <div class="bottom_box">一共匹配项目</div>
        </div>
        <div class="three_box_four_two">
          <div class="num_box"><span>26</span>亿</div>
          <div class="bottom_box">最高可获补助</div>
        </div>
        <div class="three_box_four_three">
          <div class="nums_box">1932 <span>个</span></div>
          <div class="texts_box">拥有知识产权</div>
        </div>
        <div class="three_box_four_three">
          <div class="nums_box">6<span>项</span></div>
          <div class="texts_box">曾获立项</div>
        </div>
        <div class="three_box_four_three">
          <div class="nums_box">0 <span>万</span></div>
          <div class="texts_box">曾获补助</div>
        </div>
        <div class="three_box_four_six">创新能力分析</div>
      </div>
      <el-divider></el-divider> -->
			<!--政策级别-->
			<div class="filter-list">
				<div class="filter-item">
					<div class="filter-key" style="float: left">政策级别：</div>
					<div class="filter-values" :class="{ filtercollapse: whether }">
						<ul class="clear">
							<li :class="{ Active: Active == item.name }" v-for="(item, index) in structureList" @click="jie_gou(index, item)" :key="index">
								<a onclick="return false;">{{ item.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!--申报状态-->
			<div class="filter-list">
				<div class="filter-item">
					<div class="filter-key" style="float: left">申报状态：</div>
					<div class="filter-values" :class="{ filtercollapse: whether }">
						<ul class="clear">
							<li :class="{ Active_two: Active_two == item.name }" v-for="(item, index) in structureList_two" @click="jie_gou_two(index, item)" :key="index">
								<a onclick="return false;">{{ item.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<ul class="filters">
				<li class="fitler-item">
					<span class="nameFrame">已经选择：</span>
					<ul class="cates">
						<li class="filtered-item" v-for="(sureItem, index) in FilterList" :key="index">
							{{ sureItem }}
							<i class="el-icon-close" @click="minItemClick(index)"></i>
						</li>
						<li class="clear-all-filtered" @click="resetClick">清除筛选</li>
					</ul>
				</li>
			</ul>

			<div class="tab_box">
				<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
					<el-tab-pane label="" name="first">
						<span slot="label">
							最新
							<i class="el-icon-bottom"></i>
						</span>
						<el-table :header-cell-style="{ background: '#F7F4F4', color: '#606266' }" :data="tableData" style="width: 100%">
							<el-table-column label="名称" min-width="65" align="center">
								<!-- <template slot-scope="scope">{{ scope.row.title }}</template> -->
								<template slot-scope="scope">
									<p @click="handleXq(scope.row.id)">{{ scope.row.title }}</p>
								</template>
							</el-table-column>

							<el-table-column label="发布地区" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.provincename }}
								</template>
							</el-table-column>

							<el-table-column label="发布时间" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.created_at | formatDate }}
								</template>
							</el-table-column>

							<el-table-column label="截止时间" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.endtime | formatDate}}
								</template>
							</el-table-column>

							<el-table-column label="最高补助" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.subsidy.name }}
								</template>
							</el-table-column>
						</el-table>
						<div class="bottom_boxes">
							<div class="block">
								<el-pagination
									background
									@size-change="sizeChange"
									@current-change="currentChange"
									layout="total, prev, pager, next"
									:current-page.sync="pageNum"
									:page-size="pageSize"
									:total="total"
								></el-pagination>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="" name="second">
						<span slot="label">
							热度
							<i class="el-icon-bottom"></i>
						</span>
						<el-table :header-cell-style="{ background: '#F7F4F4', color: '#606266' }" :data="tableData" style="width: 100%">
							<el-table-column label="名称" min-width="65" align="center">
								<!-- <template slot-scope="scope">{{ scope.row.title }}</template> -->
								<template slot-scope="scope">
									<p @click="handleXq(scope.row.id)">{{ scope.row.title }}</p>
								</template>
							</el-table-column>

							<el-table-column label="发布地区" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.provincename }}
								</template>
							</el-table-column>

							<el-table-column label="发布时间" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.created_at | formatDate }}
								</template>
							</el-table-column>

							<el-table-column label="截止时间" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.endtime }}
								</template>
							</el-table-column>

							<el-table-column label="最高补助" min-width="65" align="center">
								<template slot-scope="scope">
									{{ scope.row.subsidy.name }}
								</template>
							</el-table-column>
						</el-table>
						<div class="bottom_boxes">
							<div class="block">
								<el-pagination
									background
									@size-change="sizeChange"
									@current-change="currentChange"
									layout="total, prev, pager, next"
									:current-page.sync="pageNum"
									:page-size="pageSize"
									:total="total"
								></el-pagination>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
import { jibie, policy_system, policyp } from '../../common/js/api';
import { formatDate } from '../../../common/js/times'; //时间
export default {
	name: 'zhengcepp',
	data() {
		return {
			num1: 0,
			num2: 0,
			FilterList: {}, //已经选择数据
			dynamicTags: [],
			inputVisible: false,
			total: 0,
			pageSize: 6,
			pageNum: 1,
			input4: '',
			tableData: [],
			activeName: 'first',
			jie: '',
			jie_two: '',
			jie_three: '',
			jie_four: '',
			whether: false,
			Active: '不限', //荣誉
			Active_two: '不限',
			Active_three: '不限',
			Active_four: '不限',
			structureList: [],
			structureList_two: [{ name: '申报中', isSelected: false, id: 1 }, { name: '截止', isSelected: false, id: 2 }],
			structureList_three: [
				{ name: '不限', isSelected: false },
				{ name: '北京', isSelected: false },
				{ name: '上海', isSelected: false },
				{ name: '广州', isSelected: false },
				{ name: '无', isSelected: false }
			],
			user: JSON.parse(localStorage.getItem('user')),
			leveltypes: '', //级别
			is_grant: '',
			viewss: '',
			newss: '',
			inputValue: '', //选中的政策级别
			inputValues: '', //
			arr1: [],
			arr2: [],
			dynamicTags_two: [],
			obj1: [],
			obj2: [],
			index: 0
		};
	},
	filters: {
		formatDate(time) {
			time = time * 1000;
			let date = new Date(time);
			return formatDate(date, 'yyyy-MM -dd');
		}
	},
	created() {
		if (this.activeName == 'first') {
			this.newss = 1;
		} else {
			this.viewss = 2;
		}
		this.zheng_ji();
		this.pi_pei();
		this.getNums(); // 获取条数
	},
	methods: {
		// 获取条数
		getNums() {
			policyp()
				.then(res => {
					console.log('获取条数', res);
					if (res.data && res.data.length > 0) {
						this.num1 = res.data[0].name || 0;
						this.num2 = res.data[1].name || 0;
					}
				})
				.catch(err => {});
		},
		// 点击搜索
		getList() {
			this.pi_pei();
		},
		// 政策详情
		handleXq(id) {
			this.$router.push({
				path: '/policy',
				query: {
					id: id
				}
			});
		},
		/* 清除筛选 */
		resetClick() {
			for (var i in this.fitlerList) {
				this.fitlerList[i].index = 0;
			}
			this.FilterList = {};
			this.Active = '';
			this.Active_two = '';
			this.leveltypes = '';
			this.is_grant = '';
			this.pi_pei();
		},
		// 已选择每项的x键
		minItemClick(minIndex) {
			if (minIndex == 0) {
				this.Active = '';
				this.leveltypes = '';
				this.pi_pei();
			} else if (minIndex == 1) {
				this.Active_two = '';
				this.is_grant = '';
				this.pi_pei();
			}
			this.$delete(this.FilterList, minIndex);
		},

		//上一页下一页按钮
		currentChange(val) {
			this.pageNum = val;
			this.pi_pei();
		},
		//每页显示几条数据按钮
		sizeChange(val) {
			this.pageSize = val;
			this.pi_pei();
		},
		pi_pei() {
			policy_system({
				leveltype: this.leveltypes, //政策级别
				views: this.viewss, //热度2
				new: this.newss, //最新1
				is_grant: this.is_grant, //申报状态 this.user.id
				page: this.pageNum,
				limits: this.pageSize,
				title: this.input4,
				member_id: JSON.parse(localStorage.getItem('user')).id
			})
				.then(res => {
					console.log('总的', res.data);
					this.total = res.data.total;
					this.tableData = res.data.data;
				})
				.catch(err => {});
		},
		zheng_ji() {
			jibie({})
				.then(res => {
					for (var i = 0; i < res.data.length; i++) {
						Object.assign(res.data[i], { isSelected: false });
					}
					this.structureList = res.data;
				})
				.catch(err => {});
		},
		handleClick(tab, event) {
			if (this.activeName == 'first') {
				this.newss = 1;
				this.viewss = '';
			} else {
				this.newss = '';
				this.viewss = 2;
			}
			this.pi_pei();
		},
		jie_gou(index, item) {
			// 商标结构
			this.jie = item.name;
			this.inputValue = item.name;
			this.Active = this.inputValue;
			this.$set(this.FilterList, 0, item.name);
			this.inputVisible = false;
			this.inputValue = '';

			this.leveltypes = item.id;
			this.pi_pei();
			if (item.name === '不限') {
				this.jie = '';
			}
		},
		jie_gou_two(index, item) {
			// 商标结构
			this.Active_two = item.name;
			this.jie_two = item.name;
			this.inputValues = item.name;
			this.Active_two = this.inputValues;
			this.$set(this.FilterList, 1, item.name);
			this.is_grant = item.id;
			this.pi_pei();
			if (item.name === '不限') {
				this.jie = '';
			}
		}
	}
};
</script>

<style scoped lang="less">
.el-table__row:hover p {
	color: #3892eb;
	cursor: pointer;
}
/* 筛选 */
.filters-list {
	padding: 0 30px;
}

.fitler-item {
	display: flex;
	align-items: flex-start;
	font-size: 14px;
	color: #b1afaf;
	padding: 16px 30px 0;
}

.nameFrame {
	color: #101010;
	flex: none;
	line-height: 27px;
	margin-left: 12px;
}

.cates {
	margin-left: 17px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.cates li {
	cursor: pointer;
	margin-right: 17px;
	margin-bottom: 5px;
	padding: 0 10px;
	height: 27px;
	line-height: 27px;
}

.cates li.active {
	background: #3892eb;
	color: #fff;
	border-radius: 50px;
}

.filtered-item {
	background: transparent;
	color: #3892eb;
	border-radius: 0;
	border: 1px solid #3892eb;
	cursor: text;
}

.filtered-item i {
	cursor: pointer;
	width: 16px;
	height: 16px;
	font-size: 16px;
} /* 筛选 */
.filters-list {
	padding: 0 26px;
}

.fitler-item {
	display: flex;
	align-items: flex-start;
	font-size: 14px;
	color: #b1afaf;
	padding: 16px 17px 0;
}

.nameFrame {
	color: #101010;
	flex: none;
	line-height: 27px;
}

.cates {
	margin-left: 17px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.cates li {
	cursor: pointer;
	margin-right: 17px;
	margin-bottom: 5px;
	padding: 0 10px;
	height: 27px;
	line-height: 27px;
}

.cates li.active {
	background: #3892eb;
	color: #fff;
	border-radius: 50px;
}

.filtered-item {
	background: transparent;
	color: #3892eb;
	border-radius: 0;
	border: 1px solid #3892eb;
	cursor: text;
}

.filtered-item i {
	cursor: pointer;
	width: 16px;
	height: 16px;
	font-size: 16px;
}
.el-tag {
	margin-left: 90px;
	margin-top: 10px;
}
.el-tag + .el-tag {
	margin-left: 30px;
}
.button-new-tag {
	margin-left: 30px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 30px;
	vertical-align: bottom;
}
/deep/.el-tabs__content {
	overflow: unset;
}
.bottom_boxes {
	.block {
		width: 400px;
		margin: 10px auto;
	}
}
/deep/.el-table {
	width: 938px;
	border: 1px solid #eee;
	border-top: none;
	margin-top: -16px;
}
/deep/.el-tabs__nav {
	width: 100%;
	height: 50px;
	line-height: 50px;
}
.filter-item {
	padding-top: 15px;
	position: relative;
	.filter-key {
		/*float: left;*/
		width: 56px;
		padding-left: 30px;
		padding-right: 30px;
		white-space: nowrap;
		/*overflow: hidden;*/
		margin-top: 15px;
		height: 20px;
		line-height: 20px;
		color: #101010;
		cursor: default;
	}
	.filter-values {
		margin-left: 150px;
		margin-right: 110px;
		/*border-bottom: 1px #f0f0f0 dashed;*/
		color: #b1afaf;
		transition: all 0.3s;
	}
	.filtercollapse {
		height: 35px;
		overflow: hidden;
	}
	.filter-options {
		position: absolute;
		right: 30px;
		top: 14px;
		visibility: hidden;
		.filter-more {
			padding-right: 16px;
			color: #999;
			line-height: 16px;
			display: inline-block;
		}
	}
}
.filter-list .Active a {
	padding: 5px 10px;
	color: #ffffff;
	background: #3892eb;
	font-size: 14px;
	/*color: #00bb00;*/
}
.filter-list .Active_two a {
	padding: 5px 10px;
	font-size: 14px;
	color: #ffffff;
	background: #3892eb;
	/*color: #00bb00;*/
}
.filter-list .Active_three a {
	padding: 5px 10px;
	font-size: 14px;
	color: #ffffff;
	background: #3892eb;
	/*color: #00bb00;*/
}
.filter-list .Active_four a {
	padding: 5px 10px;
	font-size: 14px;
	color: #ffffff;
	background: #3892eb;
	/*color: #00bb00;*/
}
.filter-list li {
	float: left;
	margin: 15px 40px 15px 0px;
	height: 20px;
	line-height: 20px;
	cursor: pointer;
}
.cf:after,
.clear:after {
	content: '';
	display: block;
	height: 0;
	visibility: hidden;
	clear: both;
}
/deep/.el-input__icon {
	font-size: 30px;
	line-height: 70px;
}
/deep/.el-input__inner {
	font-size: 20px;
	padding-left: 40px;
	width: 945px;
	height: 72px;
	line-height: 20px;
	border-radius: 10px;
	border: 1px solid rgba(187, 187, 187, 100);
}
/deep/.el-input {
	margin: 30px 10% 0px 10%;
}
.container {
	background-color: #f8f9fa;
	width: 100%;
	height: auto;
	overflow: hidden;
	.one_box {
		width: 1200px;
		margin: 0 auto;
		.one_box_nei {
			text-align: center;
			width: 800px;
			margin: 60px auto 0px auto;
			display: flex;
			align-items: center;
			justify-content: space-around;
			.num_box {
				color: rgba(52, 136, 219, 100);
				font-size: 40px;
				span {
					font-size: 30px;
				}
			}
			.content {
				line-height: 50px;
				color: rgba(16, 16, 16, 100);
				font-size: 20px;
			}
			.line {
				width: 1px;
				height: 54px;
				background-color: rgba(187, 187, 187, 100);
			}
		}
		.search_big_box {
			width: 1200px;
			margin: 0px auto 30px auto;
		}
		.search_box {
			margin: 0 auto;
			display: flex;
			align-items: center;
			.search {
				z-index: 99;
				margin-top: 30px;
				margin-left: -30px;
				width: 106px;
				height: 43px;
				line-height: 43px;
				border-radius: 4px;
				background-color: rgba(56, 146, 235, 100);
				color: rgba(235, 245, 254, 100);
				font-size: 20px;
				text-align: center;
				font-family: Microsoft Yahei;
				border: 1px solid rgba(255, 255, 255, 100);
				span {
					cursor: pointer;
				}
			}
		}
	}
	.two_box {
		background-color: #fff;
		width: 100%;
		height: 144px;
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		text-align: center;
		box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.11);
		font-family: Roboto;
		border: 1px solid rgba(255, 255, 255, 100);
		line-height: 50px;
		.box_nei {
			margin-top: 25px;
			font-weight: 600;
			color: rgba(52, 136, 219, 100);
			font-size: 28px;
			text-align: center;
		}
		.time_box {
			text-align: center;
			color: rgba(52, 136, 219, 100);
			font-size: 14px;
		}
	}
	.three_box {
		margin: 30px auto;
		width: 1006px;
		height: auto;
		line-height: 20px;
		border-radius: 0px 5px 5px 0px;
		background-color: rgba(255, 255, 255, 100);
		color: rgba(16, 16, 16, 100);
		font-size: 14px;
		text-align: left;
		font-family: Roboto;
		border: 1px solid rgba(255, 255, 255, 100);
		.tab_box {
			margin: 30px;
			.bottom_boxes {
				width: 944px;
				height: 50px;
				line-height: 20px;
				background-color: rgba(247, 244, 244, 100);
				border: 1px solid #eee;
			}
		}
		.three_box_one {
			text-align: left;
			margin: 30px;
			font-weight: 600;
			color: rgba(16, 16, 16, 100);
			font-size: 20px;
		}
		.three_box_two {
			margin-left: 30px;
			width: 118px;
			height: 24px;
			border-radius: 22px;
			background-color: rgba(189, 70, 70, 100);
			color: rgba(235, 245, 254, 100);
			font-size: 14px;
			text-align: center;
			font-family: Microsoft Yahei;
			border: 1px solid rgba(255, 255, 255, 100);
		}
		.three_box_three {
			color: rgba(16, 16, 16, 100);
			font-size: 16px;
			margin: 30px;
			display: flex;
			justify-content: space-between;
		}
		.three_box_four {
			margin: 0px 30px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.three_box_four_one {
				width: 143px;
				height: 151px;
				line-height: 50px;
				border-radius: 10px;
				background-color: rgba(52, 112, 219, 86);
				text-align: center;
				box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
				.num_box {
					margin-top: 25px;
					font-size: 16px;
					color: #fff;
					span {
						color: rgba(238, 180, 127, 100);
						font-size: 30px;
					}
				}
				.bottom_box {
					color: rgba(255, 255, 255, 100);
					font-size: 18px;
				}
			}
			.three_box_four_two {
				width: 143px;
				height: 151px;
				line-height: 50px;
				border-radius: 10px;
				background-color: rgba(52, 112, 219, 86);
				text-align: center;
				box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
				.num_box {
					margin-top: 25px;
					font-size: 16px;
					color: #fff;
					span {
						color: #fff;
						font-size: 30px;
					}
				}
				.bottom_box {
					color: rgba(255, 255, 255, 100);
					font-size: 18px;
				}
			}
			.three_box_four_three {
				text-align: center;
				width: 143px;
				line-height: 30px;
				color: rgba(52, 89, 219, 100);
				font-size: 28px;
				.nums_box {
					span {
						font-size: 18px;
					}
				}
				.texts_box {
					color: rgba(0, 0, 0, 1);
					font-size: 18px;
				}
			}
			.three_box_four_six {
				width: 143px;
				height: 43px;
				line-height: 43px;
				border-radius: 39px;
				background-color: rgba(235, 142, 56, 100);
				color: rgba(235, 245, 254, 100);
				font-size: 18px;
				text-align: center;
				font-family: Microsoft Yahei;
				border: 1px solid rgba(255, 255, 255, 100);
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.container .one_box{
		width: 98%;
	}
	/deep/.el-input__inner{
		width: 40rem;
		font-size: 1.6rem;
	}
	.container .one_box .search_box .search{
		font-size: 1.6rem;
		width: 8rem;
		height: 2.8rem;
		line-height: 2.8rem;
		margin-left: -5rem;
	}
	.filter-item .filter-key{
		font-size: 1.5rem;
		width: 8rem;
	}
	.filter-item .filter-values{
		margin-left: 9rem;
	}
	.filter-list li{
		font-size: 1.4rem;
	}
	.fitler-item{
		font-size: 1.4rem;
	}
	/deep/.el-tabs__item{
		font-size: 1.4rem;
	}
}
</style>
